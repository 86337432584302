import { WidgetConfiguration } from 'types';

export type WidgetConfigProvider = (
  injectionPoint: Element,
) => Partial<WidgetConfiguration>;

// an enum to refer to what can be extracted from data attributes
export enum DataAttribute {
  VARIANT_ID = 'variantId',
  WIDGET_ID = 'widgetId',
  CLIENT_IP = 'clientIp',
}

// maps the attribute keys from DataAttribute to their value types
interface DataAttributeTypes {
  [DataAttribute.VARIANT_ID]: string;
  [DataAttribute.WIDGET_ID]: string;
  [DataAttribute.CLIENT_IP]: string;
}

// type T<D extends DATA_ATTRIBUTE[]> = DataAttributeKeys[Extract<DATA_ATTRIBUTE, D[keyof D]>];
export type ExtractDataReturnType<D extends DataAttribute[]> = Partial<{
  [Key in Extract<DataAttribute, D[keyof D]>]:
    | DataAttributeTypes[Key]
    | undefined;
}>;

export enum OgTag {
  URL = 'URL',
  TITLE = 'TITLE',
}

export enum OgTagSelector {
  URL = 'meta[property="og:url"]',
  TITLE = 'meta[property="og:title"]',
}

export const OgTagKeys = {
  [OgTag.URL]: 'url',
  [OgTag.TITLE]: 'title',
} as const;

export type ExtractOgReturnType<T extends OgTag[]> = Partial<{
  [Key in (typeof OgTagKeys)[Extract<OgTag, T[keyof T]>]]: string | undefined;
}>;

export const URL_WIDGET_CONFIG_KEYS: Array<keyof WidgetConfiguration> = [
  'clientIp',
  'direction',
  'title',
  'url',
  'variantId',
  'widgetId',
];
